.visible-hand {
	display: none;
	justify-content: center;
	margin-top: 10px;
}

.pulse {
	width: 6px;
	height: 6px;
	background-color: #74cf2a;
	border-radius: 50%;
	margin-right: 7px;
}

._section-title {
	font-family: "Playfair Display";
	font-size: 70px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: normal;
	line-height: calc(80 / 70 * 100%);
	text-align: center;
	letter-spacing: -1.75px;
	i {
		font-style: italic;
	}

	@media (max-width: ($md2 + px)) {
		font-size: 60px;
	}

	@media (max-width: ($md3 + px)) {
		font-size: 55px;
	}

	@media (max-width: ($md4 + px)) {
		font-size: 50px;
	}
	@media (max-width: ($md5 + px)) {
		font-size: 38px;
	}
	@media (max-width: ($md6 + px)) {
		font-size: 33px;
	}
}

._main-btn {
	width: 370px;
	height: 96px;
	box-shadow: 0 20px 50px rgba(193, 152, 129, 0.25);
	border-radius: 6px;
	background-color: #c19881;
	font-size: 14px;
	font-weight: 800;
	line-height: calc(22 / 14 * 100%);
	text-transform: uppercase;
	color: #fff;
	font-style: normal;
	letter-spacing: 0.28px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 15px;

	&:hover {
		background-color: #a17e6a;
	}

	@media (max-width: ($md5 + px)) {
		width: 100% !important;
	}
	@media (max-width: ($md6 + px)) {
		padding: 0 10px;

		font-size: 12.5px;
		br {
			display: none;
		}
	}
}

._form-control {
	width: 340px;
	height: 96px;
	border-radius: 6px;
	border: 1px solid #ebeced;
	background-color: #fffcfb;
	font-size: 17px;
	font-weight: 500;
	font-style: normal;
	padding-left: 38px;
	letter-spacing: normal;
	line-height: calc(26 / 17 * 100%);
	@media (max-width: ($md3 + px)) {
		width: 370px;
	}
	@media (max-width: ($md5 + px)) {
		width: 100% !important;
	}

	@media (max-width: ($md6 + px)) {
		height: 86px;
	}
}

._form-control::placeholder {
	color: rgba(0, 0, 0, 0.5);
}

.capture-form__title {
	font-size: 39px;
	font-weight: 300;
	font-style: normal;
	letter-spacing: normal;
	line-height: calc(48 / 39 * 100%);
	color: #000000;

	@media (max-width: ($md2 + px)) {
		font-size: 35px;
	}

	@media (max-width: ($md4 + px)) {
		font-size: 30px;
	}
	@media (max-width: ($md5 + px)) {
		font-size: 26px;
	}
	@media (max-width: ($md6 + px)) {
		font-size: 24px;
	}
	b {
		font-weight: 700;
		color: #111111;
	}
}

.policy {
	font-size: 14px;
	font-weight: 400;
	line-height: calc(30 / 14 * 100%);
	color: #808080;
	@media (max-width: ($md5 + px)) {
		line-height: 1.5;
	}
}

.hidden-br {
	display: none;
}

.policy__link {
	color: #c19881;
	text-decoration: underline;
}

.policy__checkbox {
}

.policy__label {
	position: relative;
	cursor: pointer;
	padding-left: 27px;

	&::before {
		content: "";
		width: 18px;
		height: 18px;
		border-radius: 3px;
		background-color: #ffff;
		border: 1px solid #000000;
		opacity: 0.1;
		box-sizing: border-box;
		position: absolute;
		left: 0;
		top: 6px;
	}

	&::after {
		width: 10px;
		height: 7px;
		box-sizing: border-box;
		background-image: url(../img/check.png);
		background-size: contain;
		content: "";
		position: absolute;
		left: 4px;
		top: 12px;
		opacity: 0;
		transition: all 0.1s linear;
		background-repeat: no-repeat;
		background-position: center;
	}
	@media (max-width: ($md5 + px)) {
		padding-top: 4px;
	}
}

.policy__checkbox:checked + .policy__label::after {
	opacity: 1;
	transition: all 0.1s linear;
}

.was-validated ._form-control:invalid {
	border: 1px solid #ff314e;
}

.was-validated ._form-control:invalid::placeholder {
	color: #ff6c34;
}

.policy-modal {
	// .policy-modal__dialog

	&__dialog {
	}

	// .policy-modal__content

	&__content {
	}

	// .policy-modal__body

	&__body {
	}

	// .policy-modal__title

	&__title {
	}

	// .policy-modal__text

	&__text {
	}
}
