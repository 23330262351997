.modal-content {
	border: none;
	border-radius: 10px;
	position: relative;
	overflow: hidden;
}

.modal-dialog {
	@media (max-width: ($md3 + px)) {
		margin-left: auto;
		margin-right: auto;
	}
}

.modal-content .close {
	position: absolute;
	right: 20px;
	top: 23px;
	z-index: 10;
	opacity: 1;
	@media (max-width: ($md3 + px)) {
		right: 15px;
		top: 15px;
	}
}

.modal-body {
	border-radius: 10px;
	padding: 0;
}

.catalog-modal {
	// .catalog-modal__dialog

	&__dialog {
		max-width: 800px;

		@media (max-width: ($md2 + px)) {
			max-width: 750px;
		}
		@media (max-width: ($md3 + px)) {
			max-width: 450px;
		}
	}

	// .catalog-modal__content

	&__content {
	}

	// .catalog-modal__body

	&__body {
	}

	// .catalog-modal__container

	&__container {
	}

	.policy {
		margin-left: 0px;
	}

	.capture-form__title {
		br {
			display: none;
		}
		max-width: none;
	}

	.capture-form__block {
		padding: 40px;

		@media (max-width: ($md3 + px)) {
			padding: 30px;
		}

		@media (max-width: ($md5 + px)) {
			padding: 20px;
		}
	}
}

.callback-modal {
	// .callback-modal__dialog

	&__dialog {
		max-width: 450px;
	}

	// .callback-modal__content

	&__content {
	}

	.capture-form__subtitle {
		color: #000000;
		font-size: 22px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: 32px;

		margin-top: 10px;
	}

	.capture-form__title {
		max-width: none;
		font-size: 35px;
		br {
			display: block !important;
		}
	}

	.capture-form__block {
		padding: 35px;

		@media (max-width: ($md3 + px)) {
			padding: 30px;
		}

		@media (max-width: ($md5 + px)) {
			padding: 20px;
		}
	}

	.capture-form__inner {
		flex-direction: column;
	}

	.capture-form__button,
	.capture-form__phone {
		width: 100%;
	}

	.policy {
		margin-left: 0px;
	}

	.capture-form__form {
		margin-top: 20px;
	}
	// .callback-modal__body

	&__body {
	}

	// .callback-modal__container

	&__container {
	}
}

.policy-modal {
	// .policy-modal__dialog

	&__dialog {
		max-width: 700px;
	}

	// .policy-modal__content

	&__content {
	}

	// .policy-modal__body

	&__body {
		padding: 30px;
		padding-top: 40px !important;

		@media (max-width: ($md2 + px)) {
			padding: 20px;
		}

		@media (max-width: ($md5 + px)) {
			padding: 15px;
		}
	}

	// .policy-modal__title

	&__title {
		font-size: 25px;
		text-align: center;
	}

	// .policy-modal__text

	&__text {
		margin-top: 20px;
		font-size: 16px;
		line-height: 1.4;
	}
}

.thanks-body {
	.footer {
		margin-top: 0;
	}
}
.section-thanks {
	background-image: url("../img/thanks-back.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding-top: 260px;
	height: 867px;
	@media (max-width: ($md1 + px)) {
		height: 90vh;
	}
	@media (max-width: ($md2 + px)) {
		padding-top: 200px;
	}
	@media (max-width: ($md3 + px)) {
		height: auto;
		padding-top: 120px;
	}

	@media (max-width: ($md4 + px)) {
		padding-top: 150px;
	}

	// .section-thanks__man
	&__container {
		height: 100%;

		@media (max-width: ($md3 + px)) {
			display: flex;
			flex-direction: column;
			gap: 15px;
		}
	}
	&__man {
		position: absolute;
		right: -76px;
		bottom: 0;

		@media (max-width: ($md2 + px)) {
			img {
				max-width: 90%;
			}

			right: -300px;
		}
		@media (max-width: ($md3 + px)) {
			position: static;
			order: 2;
		}
	}

	// .section-thanks__architect

	&__architect {
		position: absolute;
		bottom: 144px;
		right: 77px;
		display: flex;
		gap: 14px;
		align-items: flex-start;

		@media (max-width: ($md2 + px)) {
			right: 500px;
			bottom: 200px;
		}

		@media (max-width: ($md3 + px)) {
			bottom: 10%;
			right: 10%;
		}

		@media (max-width: ($md6 + px)) {
			right: 5%;
		}
	}

	// .section-thanks__circle

	&__circle {
		width: 9px;
		height: 9px;
		background-color: #a68574;
		border-radius: 50%;
		margin-top: 15px;
	}

	// .section-thanks__text

	&__text {
		color: #ffffff;
		font-size: 28px;
		font-weight: 200;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(34.16 / 28 * 100%);

		@media (max-width: ($md5 + px)) {
			font-size: 22px;
		}
	}

	// .section-thanks__title

	&__title {
		font-family: "Playfair Display";
		font-size: 60px;
		font-weight: 400;
		color: #ffffff;
		line-height: calc(70 / 60 * 100%);
		i {
			font-style: italic;
		}

		@media (max-width: ($md1 + px)) {
			br {
				display: none;
			}
			max-width: 50%;
		}

		@media (max-width: ($md2 + px)) {
			font-size: 50px;
			max-width: 70%;
		}
		@media (max-width: ($md3 + px)) {
			max-width: 100%;
			br {
				display: block;
			}
			font-size: 43px;
		}

		@media (max-width: ($md4 + px)) {
			font-size: 40px;
			br {
				display: none;
			}
		}
		@media (max-width: ($md5 + px)) {
			font-size: 35px;
		}
	}

	// .section-thanks__subtitle

	&__subtitle {
		font-size: 33px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: 26.16px;
		font-weight: 200;
		color: #ffffff;
		margin-top: 35px;
		b {
			font-weight: 600;
		}

		@media (max-width: ($md1 + px)) {
			max-width: 30%;
			line-height: 1.3;
		}

		@media (max-width: ($md2 + px)) {
			max-width: 40%;
		}
		@media (max-width: ($md3 + px)) {
			max-width: 100%;
			margin-top: 15px;
		}

		@media (max-width: ($md4 + px)) {
			font-size: 30px;
		}

		@media (max-width: ($md5 + px)) {
			font-size: 25px;
		}
	}
}
