@import "fonts";
@import "vars";
@import "global";
@import "mixins";
@import "parts/modal";

.header {
	z-index: 9;
	// .header__container

	&__container {
		position: absolute;
		top: 25px;
		left: 0;
		width: 100%;
		padding: 0 15px;
		display: flex;
		line-height: 125%;
		justify-content: space-between;
		align-items: flex-start;
		@media (max-width: ($md6 + px)) {
			gap: 30px;
		}
	}

	// .header__leftside

	&__leftside {
	}

	// .header__logo

	&__logo {
		cursor: pointer;
	}

	// .header__rightside

	&__rightside {
		display: flex;
		gap: 47px;
		@media (max-width: ($md4 + px)) {
			flex-direction: column;
			gap: 10px;
			align-items: flex-end;
		}
		@media (max-width: ($md6 + px)) {
			flex-shrink: 0;
			gap: 7px;
		}
	}

	// .header__wrapper

	&__wrapper {
	}

	// .header__phone

	&__phone {
		position: relative;
	}

	// .header__tel

	&__tel {
		color: #ffffff;
		font-size: 19px;
		font-weight: 600;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(19 / 24 * 100%);
		@media (max-width: ($md4 + px)) {
			font-size: 17px;
		}

		&:hover {
			color: #fff;
			text-decoration: underline;
		}
	}

	// .header__tel-icon

	&__tel-icon {
		position: absolute;
		left: -25px;

		@media (max-width: ($md5 + px)) {
			left: -20px;
		}

		@media (max-width: ($md6 + px)) {
			display: none;
		}
	}

	// .header__online

	&__online {
		display: flex;
		align-items: center;
		color: #ffffff;
		margin-top: 5px;
		font-size: 12px;
		font-weight: 400;
		@media (max-width: ($md4 + px)) {
			justify-content: flex-end;
		}
	}

	// .header__callback

	&__callback {
	}

	// .header__callback-btn

	&__callback-btn {
		width: 134px;
		height: 34px;
		border-radius: 17px;
		background-color: #a68574;
		font-size: 10px;
		font-weight: 800;
		font-style: normal;
		letter-spacing: normal;
		line-height: 24px;
		text-transform: uppercase;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover {
			background-color: #8a6e60;
		}
	}
}

.section-1 {
	padding-top: 143px;
	background-image: url(../img/sec1-back.png);
	background-size: cover;
	background-position: center;
	padding-bottom: 140px;
	@media (max-width: ($md3 + px)) {
		padding-bottom: 30px;
	}

	// .section-1__container

	&__container {
	}

	// .section-1__header

	&__header {
		display: flex;
		align-items: flex-end;
		position: relative;
		@media (max-width: ($md2 + px)) {
			flex-direction: column;
			align-items: flex-start;
			gap: 20px;
		}

		@media (max-width: ($md5 + px)) {
			gap: 15px;
		}
	}

	// .section-1__title

	&__title {
		color: #fff;
		font-family: "Playfair Display";
		font-size: 75px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(80 / 75 * 100%);
		i {
			font-style: italic;
		}

		img {
			vertical-align: middle;
		}

		@media (max-width: ($md2 + px)) {
			font-size: 65px;
		}

		@media (max-width: ($md3 + px)) {
			font-size: 60px;
		}

		@media (max-width: ($md4 + px)) {
			font-size: 50px;
			img {
				max-width: 54px;
			}
		}

		@media (max-width: ($md5 + px)) {
			font-size: 45px;
		}
	}

	// .section-1__subtitle

	&__subtitle {
		font-size: 32px;
		font-weight: 200;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(43 / 32 * 100%);
		color: #fff;
		margin-bottom: 7px;
		@media (max-width: ($md2 + px)) {
			font-size: 30px;
			max-width: 700px;
			br {
				display: none;
			}
		}

		@media (max-width: ($md3 + px)) {
			font-size: 25px;
			max-width: 500px;
		}

		@media (max-width: ($md4 + px)) {
			font-size: 23px;
		}
	}

	// .section-1__video-block

	&__video-block {
		width: 118px;
		height: 118px;
		background-color: #f5ce17;
		border-radius: 50%;
		background-image: url(../img/play.png);
		background-repeat: no-repeat;
		background-position: 55.5% center;
		transition: 0.3s all;
		position: absolute;
		right: 243px;
		top: 15px;

		&:hover {
			background-color: #e0a911;
		}

		@media (max-width: ($md1 + px)) {
			right: 20px;
		}

		@media (max-width: ($md4 + px)) {
			width: 90px;
			height: 90px;
		}

		@media (max-width: ($md5 + px)) {
			top: 70px;
			right: 7px;
			width: 80px;
			height: 80px;
			background-size: 25px;
		}
		@media (max-width: ($md6 + px)) {
			top: 95px;
			right: 7px;
			width: 65px;
			height: 65px;
			background-size: 20px;
		}
	}

	// .section-1__cursor

	&__cursor {
		position: absolute;
		bottom: 4px;
		right: -25px;
		@media (max-width: ($md5 + px)) {
			right: -15px;
			bottom: -5px;
		}

		@media (max-width: ($md6 + px)) {
			bottom: -10px;
		}
	}

	// .section-1__items

	&__items {
		margin-top: 30px;
		display: flex;
		gap: 20px;
		@media (max-width: ($md2 + px)) {
			gap: 12px;
		}

		@media (max-width: ($md3 + px)) {
			gap: 10px;
			margin-top: 20px;
		}

		@media (max-width: ($md4 + px)) {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 15px;
		}

		@media (max-width: ($md5 + px)) {
			margin-top: 15px;
		}
	}

	// .section-1__item

	&__item {
		display: flex;
		flex-direction: column;
		height: 100%;
		@media (max-width: ($md4 + px)) {
			img {
				width: 100%;
				height: 100%;
			}
		}
	}

	// .section-1__item-text
	.hidden-br {
		display: none;
		@media (max-width: ($md4 + px)) {
			display: block;
		}
	}
	&__item-text {
		margin-top: 21px;
		font-size: 15px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(26 / 15 * 100%);
		text-align: center;
		text-transform: uppercase;
		color: #fff;
		@media (max-width: ($md3 + px)) {
			font-size: 12px;
		}
		@media (max-width: ($md4 + px)) {
			font-size: 13px;
			margin-top: 10px;
		}
	}

	// .section-1__button

	&__button {
		width: 405px;
	}

	// .section-1__send

	&__send {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 14px;
		width: 408px;

		height: 96px;
		border-radius: 6px;
		border: 1px solid rgba(225, 225, 225, 0.2);
		@media (max-width: ($md5 + px)) {
			width: 100%;
			gap: 10px;

			picture {
				max-width: 70px;
			}
		}
	}

	// .section-1__send-text

	&__send-text {
		color: #ffffff;
		font-size: 14px;
		font-weight: 800;
		line-height: calc(26 / 14 * 100%);
		text-transform: uppercase;
		@media (max-width: ($md6 + px)) {
			font-size: 13px;
		}
	}

	&__footer {
		display: flex;
		gap: 40px;
		margin-top: 43px;

		@media (max-width: ($md2 + px)) {
			gap: 20px;
		}
		@media (max-width: ($md3 + px)) {
			flex-direction: column;
			align-items: center;
			gap: 10px;
			margin-top: 25px;
			justify-content: center;
		}
	}
}

.section-2 {
	margin-top: 80px;

	@media (max-width: ($md3 + px)) {
		margin-top: 30px;
	}

	// .section-2__container

	&__container {
	}

	// .section-2__title

	&__title {
		color: #111111;
	}

	// .section-2__items

	&__items {
		margin-top: 58px;
		display: grid;
		grid-template-areas:
			"a b c d"
			"e e f f";
		gap: 10px 13px;
		@media (max-width: ($md2 + px)) {
			gap: 7px;
		}

		@media (max-width: ($md3 + px)) {
			grid-template-areas:
				"a b"
				"c d"
				"e e "
				"f f";
			justify-content: center;
			margin-top: 20px;
		}
	}

	// .section-2__item

	&__item {
		position: relative;
		&:nth-of-type(1) {
			grid-area: a;
		}
		&:nth-of-type(2) {
			grid-area: b;
		}
		&:nth-of-type(3) {
			grid-area: c;
		}
		&:nth-of-type(4) {
			grid-area: d;
		}
		&:nth-of-type(5) {
			grid-area: e;
			@media (max-width: ($md3 + px)) {
				img {
					width: 100%;
				}
			}
		}
		&:nth-of-type(6) {
			grid-area: f;
			@media (max-width: ($md3 + px)) {
				img {
					width: 100%;
				}
			}
		}
	}

	// .section-2__absolute5

	&__absolute5 {
		position: absolute;
		top: 0;
		left: -34px;
		@media (max-width: ($md1 + px)) {
			max-width: 250px;
		}
		@media (max-width: ($md2 + px)) {
			max-width: 220px;
		}
		@media (max-width: ($md3 + px)) {
			max-width: 60%;
		}
	}

	// .section-2__absolute6

	&__absolute6 {
		position: absolute;
		right: 0;
		top: 21px;
		right: -24px;
		@media (max-width: ($md1 + px)) {
			max-width: 260px;
		}
		@media (max-width: ($md2 + px)) {
			max-width: 220px;
		}
		@media (max-width: ($md3 + px)) {
			max-width: 60%;
		}
	}

	// .section-2__picture

	&__picture {
		margin-top: 13px;
		display: flex;
		justify-content: center;
		@media (max-width: ($md3 + px)) {
			margin-top: 0;
		}
	}
}

.section-3 {
	margin-top: 29px;
	position: relative;
	z-index: 2;
	// .section-2__container

	&__container {
	}

	// .section-2__form
}

.capture-form {
	// .capture-form__block

	&__block {
		background-image: url("../img/form-back.png");
		background-size: cover;
		position: relative;
		background-position: center;
		padding: 49px 50px 52px 66px;

		box-shadow: 0 -30px 60px rgba(21, 23, 40, 0.03);
		border-radius: 8px;
		background-color: #ffffff;
		z-index: 0;
		@media (max-width: ($md1 + px)) {
			padding: 45px;
		}
		@media (max-width: ($md2 + px)) {
			padding: 40px;
		}
		@media (max-width: ($md3 + px)) {
			padding: 30px;
			overflow: hidden;
		}

		@media (max-width: ($md4 + px)) {
			border-radius: 8px 8px 0 0;
		}

		@media (max-width: ($md5 + px)) {
			padding: 15px;
		}
	}

	// .capture-form__book

	&__book {
		position: absolute;
		right: -177px;
		top: -29px;
		z-index: -1;
		@media (max-width: ($md1 + px)) {
			top: -20px;
			max-width: 580px;
		}
		@media (max-width: ($md2 + px)) {
			top: 16px;
			right: -167px;
			max-width: 530px;
		}
		@media (max-width: ($md3 + px)) {
			top: unset;
			bottom: 10px;
			filter: opacity(0.5);
			right: -100px;
			max-width: 450px;
		}
	}

	// .capture-form__pdf

	&__pdf {
		width: 109px;
		height: 109px;
		background-color: #eff3f5;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		right: 314px;
		top: 35px;

		img {
			margin-top: 35px;
		}

		@media (max-width: ($md2 + px)) {
			right: 254px;
			top: 182px;
			background-color: #fff;
		}
		@media (max-width: ($md3 + px)) {
			right: 20px;
			top: 200px;
		}

		@media (max-width: ($md4 + px)) {
			right: 20px;
			width: 90px;
			height: 90px;
			top: 180px;
		}
		@media (max-width: ($md5 + px)) {
			display: none;
		}
	}

	// .capture-form__title

	&__title {
		@media (max-width: ($md1 + px)) {
			br {
				display: none;
			}
			max-width: 62%;
		}
		@media (max-width: ($md2 + px)) {
			max-width: 63%;
		}
		@media (max-width: ($md3 + px)) {
			max-width: 100%;
		}
	}

	// .capture-form__form

	&__form {
		margin-top: 38px;

		@media (max-width: ($md3 + px)) {
			margin-top: 25px;
		}

		@media (max-width: ($md5 + px)) {
			margin-top: 20px;
		}
	}

	// .capture-form__inner

	&__inner {
		display: flex;
		gap: 11px;
		@media (max-width: ($md3 + px)) {
			flex-direction: column;
		}
	}

	// .capture-form__phone

	&__phone {
	}

	// .capture-form__button

	&__button {
		z-index: 2;
	}

	// .capture-form__policy

	&__policy {
		padding-top: 20px;
		margin-left: 350px;
		@media (max-width: ($md2 + px)) {
			margin-left: 0;
		}

		@media (max-width: ($md5 + px)) {
			padding-top: 10px;
		}
	}

	// .capture-form__footer

	&__footer {
		display: flex;
		padding: 15px 45px;
		border-radius: 0 0 8px 8px;

		justify-content: center;
		margin: auto;
		max-width: fit-content;
		color: #fff;
		align-items: center;
		background: #414141;
		@media (max-width: ($md2 + px)) {
			padding: 15px 20px;
		}
		@media (max-width: ($md3 + px)) {
			padding: 7px 15px;
		}

		@media (max-width: ($md6 + px)) {
			padding: 7px 10px;
		}
	}
}

.policy {
	// .policy__checkbox

	&__checkbox {
		position: absolute;
		z-index: -1;
		opacity: 0;
		display: block;
		width: 0;
		height: 0;
	}

	// .policy__label

	&__label {
	}

	// .policy__link

	&__link {
		&:hover {
			color: #8a6e60;
		}
	}
}
.capture-footer {
	@media (max-width: ($md3 + px)) {
		display: flex;
		flex-direction: column;
	}

	@media (max-width: ($md4 + px)) {
		width: 100%;
		max-width: none;
	}

	@media (max-width: ($md5 + px)) {
		align-items: center;
		flex-direction: row;
		gap: 20px;
	}

	.hidden-br {
		@media (max-width: ($md5 + px)) {
			display: block;
		}
	}
	// .capture-footer__contacts

	&__contacts {
		font-size: 19px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(30 / 19 * 100%);
		text-align: left;
		text-transform: uppercase;
		margin-right: 56px;
		@media (max-width: ($md2 + px)) {
			margin-right: 20px;
			font-size: 17px;
		}
		@media (max-width: ($md5 + px)) {
			margin-right: 0;
			font-size: 15px;
		}

		@media (max-width: ($md6 + px)) {
			font-size: 14px;
		}
	}

	&__links {
		display: contents;

		@media (max-width: ($md3 + px)) {
			display: flex;
		}

		@media (max-width: ($md5 + px)) {
			flex-direction: column;
		}
	}
	// .capture-footer__phone

	&__phone {
		display: flex;
		align-items: center;
		gap: 11px;
		font-size: 24px;
		font-weight: 500;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(30 / 24 * 100%);
		padding-right: 33px;
		border-right: 1px solid rgba(225, 225, 225, 0.1);
		margin-right: 39px;

		&:hover {
			text-decoration: underline;
		}

		@media (max-width: ($md2 + px)) {
			padding-right: 15px;
			margin-right: 15px;
			font-size: 22px;
		}

		@media (max-width: ($md4 + px)) {
			gap: 7px;
			font-size: 20px;
		}

		@media (max-width: ($md5 + px)) {
			border: none;
			font-size: 18px;
		}

		@media (max-width: ($md6 + px)) {
			gap: 5px;
			font-size: 17px;
		}
	}

	// .capture-footer__tel

	&__tel {
		@media (max-width: ($md2+ px)) {
			padding-bottom: 2px;
		}
		@media (max-width: ($md4 + px)) {
			padding-bottom: 0;
		}
	}

	// .capture-footer__email

	&__email {
		display: flex;
		align-items: center;
		gap: 12px;
		font-size: 20px;
		font-weight: 500;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(30 / 20 * 100%);
		&:hover {
			text-decoration: underline;
		}
		@media (max-width: ($md4 + px)) {
			gap: 7px;
		}
		@media (max-width: ($md5 + px)) {
			font-size: 18px;
		}
		@media (max-width: ($md6 + px)) {
			gap: 5px;
			font-size: 16px;
		}
	}

	// .capture-footer__mail

	&__mail {
		padding-top: 2px;
	}
}

.section-4 {
	// .section-4__container

	&__container {
		padding-top: 525px;
		padding-bottom: 110px;
		margin-top: -423px;
		position: relative;

		@media (max-width: ($md3 + px)) {
			padding-top: 475px;
			padding-bottom: 30px;
		}
	}

	// .section-4__title

	&__title {
		color: #fff;
		font-size: 60px;
		letter-spacing: -1.5px;

		@media (max-width: ($md3 + px)) {
			font-size: 55px;
		}

		@media (max-width: ($md4 + px)) {
			font-size: 50px;
		}
		@media (max-width: ($md5 + px)) {
			font-size: 40px;
		}
		@media (max-width: ($md6 + px)) {
			font-size: 35px;
		}
	}

	// .section-4__items

	&__items {
		padding: 0 72px;
		display: grid;
		grid-template-columns: repeat(4, auto);
		justify-content: space-between;
		justify-items: center;
		align-items: center;
		margin-top: 60px;

		@media (max-width: ($md2 + px)) {
			padding: 0;
		}

		@media (max-width: ($md3 + px)) {
			margin-top: 35px;
		}

		@media (max-width: ($md4 + px)) {
			grid-template-columns: repeat(2, auto);
			justify-content: center;
			gap: 10px 15px;
			picture {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
	&__item {
		@media (max-width: ($md3 + px)) {
			max-width: 90%;
		}
	}

	&__longpic {
		display: flex;
		justify-content: center;
		margin-top: 7px;
	}

	&__gift {
		position: absolute;
		top: 62px;
		left: -150px;

		@media (max-width: 1400px) {
			display: none;
		}
	}
}

.section-5 {
	margin-top: 80px;
	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
	}
	// .section-5__container

	&__container {
		max-width: 1280px;
	}

	// .section-5__title

	&__title {
	}

	// .section-5__items

	&__items {
		margin-top: 20px;
		display: grid;
		grid-template-columns: repeat(6, auto);
		align-items: center;
		@media (max-width: ($md3 + px)) {
			grid-template-columns: repeat(3, auto);
		}
	}

	// .section-5__item

	&__item {
	}

	// .section-5__items2

	&__items2 {
		margin-top: -30px;
		display: grid;
		grid-template-columns: repeat(5, auto);
		align-items: center;
		@media (max-width: ($md3 + px)) {
			grid-template-columns: repeat(3, auto);
		}
	}
}

.section-6 {
	margin-top: 78px;
	@media (max-width: ($md3 + px)) {
		margin-top: 40px;
	}
	// .section-6__container

	&__container {
		padding-top: 100px;
		padding-bottom: 385px;
		@media (max-width: ($md3 + px)) {
			padding-top: 30px;
		}
	}

	// .section-6__title

	&__title {
		color: #fff;
	}

	// .section-6__items

	&__items {
		margin-top: 60px;
		display: flex;
		align-items: center;
		gap: 30px;
		justify-content: space-between;
		@media (max-width: ($md3 + px)) {
			margin-top: 30px;
			gap: 15px;
		}
		@media (max-width: ($md4 + px)) {
			display: grid;
			grid-template-columns: repeat(2, auto);
		}
	}

	// .section-6__item

	&__item {
	}
}

.section-7 {
	margin-top: -278px;

	@media (max-width: ($md2 + px)) {
		margin-top: -310px;
	}

	@media (max-width: ($md3 + px)) {
		margin-top: -340px;
	}

	// .section-7__container

	&__container {
	}

	// .section-7__form

	&__form {
		position: relative;
	}

	// .section-7__gift

	&__gift {
		position: absolute;
		top: 99px;
		left: -165px;
	}
}

.section-8 {
	margin-top: 90px;
	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
	}
	// .section-8__container

	&__container {
	}

	// .section-8__title

	&__title {
	}

	.photo-slider {
		overflow: unset;
		width: 100%;
		margin-top: 48px;
		@media (max-width: ($md3 + px)) {
			margin-top: 30px;
		}
	}
	.swiper-wrapper {
	}
	.swiper-slide {
	}
	.swiper-pagination {
		bottom: -40px;
		@media (max-width: ($md3 + px)) {
			bottom: -30px;
		}
	}

	.swiper-slide-next,
	.swiper-slide-prev {
		filter: brightness(0.4);
	}
	.swiper-button-prev {
		width: 80px;
		height: 79px;
		background-color: #f5ce17;
		border-radius: 50%;
		background-image: url(../img/next-arrow.png);
		transform: scale(-1, -1);
		background-repeat: no-repeat;
		background-position: 55.5% center;
		left: 50px;
		top: calc(50% - 19px);
		transition: all 0.5s;
		&::after {
			display: none;
		}
		@media (max-width: ($md2 + px)) {
			left: 20px;
		}
		@media (max-width: ($md3 + px)) {
			left: 10px;
			width: 70px;
			height: 70px;
		}
		@media (max-width: ($md5 + px)) {
			width: 60px;
			height: 60px;
			top: calc(50% - 10px);
		}

		&:hover {
			background-color: #e0a911;
			transition: all 0.5s;
		}
	}
	.swiper-button-next {
		width: 80px;
		height: 79px;
		background-color: #f5ce17;
		border-radius: 50%;
		background-image: url(../img/next-arrow.png);
		right: 50px;
		background-repeat: no-repeat;
		background-position: 55.5% center;
		top: calc(50% - 19px);
		transition: all 0.5s;
		&::after {
			display: none;
		}

		&:hover {
			transition: all 0.5s;
			background-color: #e0a911;
		}

		@media (max-width: ($md2 + px)) {
			right: 20px;
		}
		@media (max-width: ($md3 + px)) {
			right: 10px;
			width: 70px;
			height: 70px;
		}
		@media (max-width: ($md5 + px)) {
			width: 60px;
			height: 60px;
			top: calc(50% - 10px);
		}
	}

	.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
	.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
		margin: 0 4.5px;
	}

	.swiper-pagination-bullet {
		width: 9px;
		height: 9px;
	}
	.swiper-pagination-bullet-active {
		background-color: #c19881;
	}
}

.section-9 {
	margin-top: 147px;
	background-image: url(../img/sec9-back.png);
	padding-top: 167px;
	background-size: cover;
	background-repeat: no-repeat;

	@media (max-width: ($md3 + px)) {
		margin-top: 80px;
		padding-top: 50px;
	}
	@media (max-width: ($md5 + px)) {
		padding-top: 30px;
	}

	// .section-9__container

	&__container {
		display: flex;
		justify-content: flex-end;
	}

	&__inner {
		padding-right: 31px;
		color: white;
		padding-bottom: 352px;
		@media (max-width: ($md1 + px)) {
			padding-right: 0;
		}
		@media (max-width: ($md2 + px)) {
			z-index: 2;
		}

		@media (max-width: ($md3 + px)) {
			max-width: 65%;
		}

		@media (max-width: ($md4 + px)) {
			max-width: 70%;
		}

		@media (max-width: ($md5 + px)) {
			max-width: 100%;
			display: flex;
			flex-direction: column;
			padding-bottom: 420px;
		}

		@media (max-width: ($md6 + px)) {
			padding-bottom: 340px;
		}
	}

	// .section-9__man

	&__man {
		position: absolute;
		left: 17px;
		bottom: 0;
		@media (max-width: ($md1 + px)) {
			left: -150px;
		}
		@media (max-width: ($md2 + px)) {
			max-width: 85%;
			left: -160px;
			bottom: 90px;
		}

		@media (max-width: ($md3 + px)) {
			max-width: 600px;
			bottom: 110px;
			left: -170px;
		}

		@media (max-width: ($md4 + px)) {
			max-width: 480px;
			bottom: 200px;
			left: -165px;
		}

		@media (max-width: ($md5 + px)) {
			max-width: 380px;
			bottom: 140px;
			left: -90px;
		}
		@media (max-width: ($md6 + px)) {
			display: none;
		}
	}

	// .section-9__title

	&__title {
		font-size: 50px;
		font-weight: 300;
		line-height: calc(60 / 50 * 100%);
		letter-spacing: -1.25px;
		i {
			font-family: "Playfair Display";
			font-size: 55px;
			font-style: italic;
			letter-spacing: -1.38px;
			@media (max-width: ($md3 + px)) {
				font-size: 50px;
				font-size: clamp(40px, 6vw, 50px);
			}
			@media (max-width: ($md4 + px)) {
				font-size: 38px;
			}
		}

		@media (max-width: ($md3 + px)) {
			font-size: clamp(34px, 6vw, 45px);
			br {
				display: none;
			}
		}

		@media (max-width: ($md4 + px)) {
			font-size: 32px;
		}

		@media (max-width: ($md6 + px)) {
			font-size: 30px;
		}
	}

	// .section-9__subtitle

	&__subtitle {
		font-size: 24px;
		font-weight: 300;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(36 / 24 * 100%);
		margin-top: 22px;
		letter-spacing: -0.6px;
		@media (max-width: ($md3 + px)) {
			br {
				display: none;
			}

			font-size: 22px;
		}

		@media (max-width: ($md4 + px)) {
			font-size: 20px;
		}

		@media (max-width: ($md5 + px)) {
			max-width: 60%;
			align-self: flex-end;
			font-size: 19px;
			margin-top: 25px;
		}

		@media (max-width: ($md6 + px)) {
			max-width: 100%;
			margin-top: 15px;
		}
	}
}

.section-10 {
	// .section-10__container

	margin-top: -233px;

	@media (max-width: ($md2 + px)) {
	}

	@media (max-width: ($md3 + px)) {
		margin-top: -310px;
	}

	// .section-7__container

	&__container {
	}

	// .section-7__form

	&__form {
		position: relative;
	}

	// .section-7__gift

	&__gift {
		position: absolute;
		top: 99px;
		left: -165px;
	}
}

.footer {
	margin-top: 142px;
	padding-top: 48px;
	border-top: 1px solid rgba(0, 0, 0, 0.05);
	padding-bottom: 48px;
	@media (max-width: ($md2 + px)) {
		margin-top: 100px;
	}
	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
		padding-top: 20px;
		padding-bottom: 20px;
	}
	// .footer__container

	&__container {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		@media (max-width: ($md6 + px)) {
			gap: 20px;
		}
	}

	// .footer__leftside

	&__leftside {
	}

	// .footer__logo

	&__logo {
		cursor: pointer;
		@media (max-width: ($md3 + px)) {
			max-width: 90%;
		}
	}

	// .footer__middle

	&__middle {
		display: flex;
		gap: 74px;

		@media (max-width: ($md1 + px)) {
			gap: 20px;
			flex-direction: column;
		}

		@media (max-width: ($md5 + px)) {
			display: none;
		}
	}

	&__wrapper {
		display: contents;
		@media (max-width: 620px) {
			display: flex;
			flex-direction: column;
			gap: 20px;
		}
	}

	// .footer__policy

	&__policy {
		color: #111111;
		font-size: 14px;
		font-weight: 500;
		text-align: left;
		text-decoration: underline;
		&:hover {
			color: #8a6e60;
		}
	}

	// .footer__send

	&__send {
		display: flex;
		gap: 19px;
		align-items: center;
		margin-top: -5px;
	}

	// .footer__send-text

	&__send-text {
		font-size: 15px;
		font-weight: 800;
		line-height: calc(23 / 15 * 100%);
		text-align: left;
		text-transform: uppercase;
	}

	// .footer__rightside

	&__rightside {
		display: flex;
		gap: 47px;
		@media (max-width: ($md2 + px)) {
			gap: 10px;
			flex-direction: column;
			align-items: flex-end;
		}
		@media (max-width: ($md6 + px)) {
			flex-shrink: 0;
		}
	}

	// .footer__rightside-left

	&__rightside-left {
	}

	// .footer__phone

	&__phone {
		position: relative;
	}

	// .footer__tel

	&__tel {
		font-size: 19px;
		font-weight: 600;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(20 / 19 * 100%);
		@media (max-width: ($md5 + px)) {
			font-size: 17px;
		}

		&:hover {
			text-decoration: underline;
		}
	}

	// .footer__tel-icon

	&__tel-icon {
		position: absolute;
		left: -25px;
		@media (max-width: ($md6 + px)) {
			display: none;
		}
	}

	// .footer__online

	&__online {
		display: flex;
		align-items: center;
		margin-top: 10px;
		font-size: 12px;
		font-weight: 400;

		@media (max-width: ($md1 + px)) {
			justify-content: flex-end;
		}
	}

	// .footer__callback

	&__callback {
	}

	// .footer__callback-btn

	&__callback-btn {
		width: 134px;
		height: 34px;
		border-radius: 17px;
		background-color: #a68574;
		font-size: 10px;
		font-weight: 800;
		font-style: normal;
		letter-spacing: normal;
		line-height: 24px;
		text-transform: uppercase;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover {
			background-color: #8a6e60;
		}
	}
}
