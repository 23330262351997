.form-check {
  display: flex;
  align-items: center;
  padding: 0;
  margin-top: 20px;
  justify-content: center;
  margin-left: 0;
  padding-right: 0;

  input {
    display: none;
    width: auto !important;
    height: auto !important;
  }

  label {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #bbb;
    letter-spacing: .28px;
    position: relative;
    padding-left: 24px;
    cursor: pointer;

    &:before {
      width: 16px;
      height: 16px;
      box-sizing: border-box;
      border: 1px solid #ddd;
      border-radius: 2px;
      content: "";
      position: absolute;
      left: 0;
      top: 0px;
      border-radius: 3px;
    }

    &:after {
      width: 10px;
      height: 10px;
      box-sizing: border-box;
      background-image: url(../img/check.svg);
      background-size: contain;
      content: "";
      position: absolute;
      left: 3px;
      top: 3px;
      opacity: 0;
      transition: all .1s linear;
      background-repeat: no-repeat;
      background-position: center;
    }

    a {
      cursor: pointer;
      color: #fec400 !important;
      text-decoration: underline !important;

      &:hover {
        text-decoration: underline !important;
        color: #e89f00 !important;
      }
    }
  }

  input:checked+label:after {
    opacity: 1;
    transition: all .1s linear;
  }
}